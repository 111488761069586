import FlowManagerAPI from '../../api/flowManager';

export const state = {
  records: [],
  flowRecord: {},
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getUiFlags(_state) {
    return _state.uiFlags;
  },

  getRecords(_state) {
    return _state.records;
  },

  getFlowRecord(_state) {
    return _state.flowRecord;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit('SET_LOADING', true);
    try {
      const response = await FlowManagerAPI.get();
      commit('SET_RECORDS', response.data);
    } catch (error) {
      // TODO: do better error
    } finally {
      commit('SET_LOADING', false);
    }
  },

  show: async ({ commit }, id) => {
    commit('SET_LOADING', true);
    try {
      const response = await FlowManagerAPI.show(id);
      commit('SET_FLOW_RECORD', response.data);
    } catch (error) {
      // TODO: do better error
    } finally {
      commit('SET_LOADING', false);
    }
  },

  update: async (
    { commit, dispatch },
    { flowManagerId, userId, agentBotId, accountId, isCheckin = false }
  ) => {
    commit('SET_LOADING', true);
    try {
      const response = await FlowManagerAPI.update(flowManagerId, {
        user_id: userId,
        agentBotId: agentBotId,
        account_id: accountId,
        isCheckin: isCheckin,
      });
      dispatch('get');
    } catch (error) {
      // TODO: do better error
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export const mutations = {
  SET_LOADING(_state, payload) {
    _state.uiFlags.isFetching = payload;
  },

  SET_RECORDS(_state, payload) {
    _state.records = payload.data;
  },

  SET_FLOW_RECORD(_state, payload) {
    _state.flowRecord = payload.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
