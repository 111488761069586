<template>
  <section class="w-full h-full">
    <flow-header />

    <div class="flex flex-col mx-8 mb-6 mt-3">
      <div v-for="(item, index) in getRecords" :key="index">
        <flow-card
          :flowManagerId="item.id"
          :agentBotId="item.agent_bot_id"
          :accountId="item.account_id"
          :botName="item.agent_bot.name"
          :isCheckout="item.checkout"
          :userId="item.user_id"
          :username="item.user ? item.user.name : ''"
          :checkoutDate="formatDate(item.checkout_datetime)"
          @checkout="checkout"
        />
      </div>
    </div>
  </section>
</template>

<script>
import FlowCard from './FlowCard.vue';
import FlowHeader from './components/FlowHeader.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    FlowCard,
    FlowHeader,
  },
  mixins: [],
  data() {
    return { flowViewSrc: '' };
  },
  computed: {
    ...mapGetters('flowManager', ['getUiFlags', 'getRecords']),
    ...mapGetters('agentBots', ['getBots']),
  },

  mounted() {
    this.$store.dispatch('flowManager/get');
  },
  methods: {
    formatDate(rawDate) {
      const date = new Date(rawDate);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();

      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${day}/${month}/${year} - ${hours}:${minutes}h`;
    },
    checkout(flowManagerId, userId, agentBotId, accountId, isCheckout) {
      this.$store.dispatch('flowManager/update', {
        flowManagerId: flowManagerId,
        userId: userId,
        agentBotId: agentBotId,
        accountId: accountId,
        isCheckout: isCheckout,
      });
    },
  },
};
</script>
