import Index from './Index.vue';
import FlowView from './FlowView.vue';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/flow/dashboard'),
    name: 'flow_dashboard',
    roles: ['administrator'],
    component: Index,
    props: {},
  },
  {
    path: frontendURL('accounts/:accountId/flow/:flow_id'),
    name: 'flow_view',
    roles: ['administrator'],
    component: FlowView,
    props: route => {
      return { flowId: parseInt(route.params.flow_id, 10) };
    },
  },
];
