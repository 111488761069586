<template>
  <div
    class="px-5 py-4 mb-3 bg-white border rounded-md dark:bg-slate-800 border-slate-50 dark:border-slate-900 shadow-md"
  >
    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-col">
        <p class="text-lg uppercase dark:text-slate-200">
          <b>{{ botName }}</b>
        </p>
      </div>
      <div class="flex flex-row space-x-4 items-center">
        <div v-if="isCheckout">
          {{ checkoutDate }}
        </div>
        <div v-if="isCheckout" class="user-thumbnail thumbnail-rounded">
          <thumbnail :username="username" :title="username" />
        </div>
        <button
          v-if="!isCheckout"
          class="text-white bg-red-400 hover:bg-red-500 dark:bg-red-600 dark:hover:bg-red-700"
          @click="handleCheckout"
        >
          Check-out
        </button>

        <button
          v-if="isCheckout && currentUserId === userId"
          class="text-black-900 dark:text-white bg-green-400 hover:bg-green-500 dark:bg-green-500 dark:hover:bg-green-600"
          @click="handleCheckout(true)"
        >
          Check-in
        </button>

        <button
          v-if="isCheckout && currentUserId === userId"
          class="text-black-900 dark:text-white bg-yellow-400 hover:bg-yellow-500 dark:bg-orange-600 dark:hover:bg-orange-500"
          @click="routeEditFlow"
        >
          Editar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Thumbnail,
  },

  props: {
    flowManagerId: {
      type: Number,
      default: 0,
    },
    agentBotId: {
      type: Number,
      default: 0,
    },
    accountId: {
      type: Number,
      default: 0,
    },
    botName: {
      type: String,
      default: '',
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    checkoutDate: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      default: '',
    },
    userId: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters({
      currentUserId: 'getCurrentUserID',
    }),
  },

  methods: {
    handleCheckout(isCheckout) {
      this.$emit(
        'checkout',
        this.flowManagerId,
        this.currentUserId,
        this.agentBotId,
        this.accountId,
        isCheckout
      );
    },
    routeEditFlow() {
      this.$router.push({
        name: 'flow_view',
        params: { flow_id: this.flowManagerId },
      });
    },
  },
};
</script>
