<template>
  <header
    class="bg-white border-b dark:bg-slate-900 border-slate-50 dark:border-slate-800"
  >
    <div class="flex justify-start w-full px-4 py-2">
      <h1
        v-if="botName === ''"
        class="m-0 mx-2 my-0 overflow-hidden text-xl text-slate-900 dark:text-slate-100 whitespace-nowrap text-ellipsis"
      >
        Flows
      </h1>
      <button v-if="botName !== ''" class="text-white bg-slate-400 hover:bg-slate-500 dark:bg-slate-600 dark:hover:bg-slate-700" @click="routeFlowDashboard">
        Voltar
      </button>
      <h1
        v-if="botName !== ''"
        class="m-0 mx-2 my-0 overflow-hidden text-xl text-slate-900 dark:text-slate-100 whitespace-nowrap text-ellipsis"
      >
        {{ botName }}
      </h1>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    isOnAFlow: {
      type: Boolean,
      default: false,
    },
    botName: {
      type: String,
      default: '',
    },
  },
  methods: {
    routeFlowDashboard() {
      this.$router.push({
        name: 'flow_dashboard',
      });
    },
  },
};
</script>
