<template>
  <div class="w-full h-full flex flex-col">
    <flow-header :botName="getFlowRecord.agent_bot.name" />
    <iframe :src="getFlowRecord.node_red.uri" title="Flow View" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FlowHeader from './components/FlowHeader.vue';

export default {
  components: {
    FlowHeader,
  },
  props: {
    flowId: Number,
  },
  computed: {
    ...mapGetters('flowManager', ['getUiFlags', 'getFlowRecord']),
    ...mapGetters({
      currentUserId: 'getCurrentUserID',
    }),
  },
  async mounted() {
    await this.$store.dispatch('flowManager/show', this.flowId);

    if (this.getFlowRecord.user_id !== this.currentUserId) {
      this.$router.push({
        name: 'flow_dashboard',
      });
    }
  },
  methods: {},
};
</script>

<style>
iframe {
  height: 99.5%;
  width: 100%;
  border: none;
}
.content-box {
  padding: 0px;
}
</style>
